const attrsDefault = {
    toggle: 'easy-toggle',
    add: 'easy-add',
    remove: 'easy-remove',
    class: 'easy-class',
    rcoe: 'easy-rcoe',
    parallel: 'easy-parallel',
    self: 'easy-self'
}

export default attrsDefault;
